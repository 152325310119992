<template>
  <div class="background">
  <!-- <el-card class="box-card" v-loading="loading">
    <div slot="header" class="clearfix">
      <span>登录</span>
    </div>
    <div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="username">
          <el-input
            v-model="form.username"
            placeholder="请输入账号"
            name="username"
            autocomplete="on"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            placeholder="请输入密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item class="btn">
          <el-button type="primary" @click="onSubmit">登录</el-button>
          <el-button>重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card> -->
  <div class="main">
  		<input type="checkbox" id="chk" aria-hidden="true"/>
  			<div class="login">
  				<div class="form">
  					<label for="chk" aria-hidden="true">登录</label>
  					<input class="input" v-model="loginForm.username" type="text" name="txt" placeholder="用户名/手机号"
            @keyup.enter="login"
            >
  					<input class="input" v-model="loginForm.password" type="password" name="pswd" placeholder="密码"
            @keyup.enter="login"
            >
  					<button @click="login"
            :disabled="loading"
            >
              <LoadingButton :size="32" v-if="loading"></LoadingButton>
              <span v-if="!loading">登录</span>
              </button>
  				</div>
  			</div>

        <div class="register">
  				<div class="form">
  					<label for="chk" aria-hidden="true">注册</label>
  					<input class="input" v-model="registForm.username" type="text" name="txt" placeholder="用户名"
            @keyup.enter="register"
            >
  					<input class="input" v-model="registForm.password" type="password" name="pswd" placeholder="密码"
            @keyup.enter="register"
            >
            <input class="input" v-model="registForm.nickname" type="text" name="text" placeholder="昵称"
            @keyup.enter="register"
            >
  					<button @click="register" style="text-align: center;"
            :disabled="loading"
            >
              <LoadingButton :size="32" v-if="loading"></LoadingButton>
              <span v-if="!loading">注册</span>
              </button>
  				</div>
  			</div>
  	</div>
  </div>
</template>
<script>
import { loginApi, getUserInfo ,registApi} from '@/api/user'
export default {
  data () {
    return {
      loading:false,
      loginForm: {
        username: '',
        password: ''
      },
      registForm:{}
    }
  },
  created(){
  },
  methods: {
    async login () {
      this.loading=true
      const res = await loginApi(this.loginForm)
      if (res.data.success === true) {
        this.$store.commit('user/updateToken', res.data.data.token)
        const result = await getUserInfo()
        if (result.data.success === true) {
          this.loading=false
          this.$message({
            message: '登录成功',
            type: 'success'
          })
          this.$store.commit('user/updateUser', result.data.data)
          this.$router.push('/')
        }
      } else {
        this.loading=false
        this.$message({
          message: res.data.errorMsg,
          type: 'error'
        })
      }
    },
    async register(){
      this.loading=true
      await registApi(this.registForm).then((res)=>{
        if(res.data.success){
          this.$message({
            message: '注册成功，赶快去登陆吧！',
            type: 'success'
          })
        }else{
          this.$message({
            message: res.data.errorMsg,
            type: 'warning'
          })
        }
        this.loading=false
      })
    }
  }
}
</script>
<style lang="less" scoped>

  .background{
    background:url(https://picx.zhimg.com/v2-908b61a41ec4bebe17a04468dcf5d834_r.jpg?source=1940ef5c) no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding-top: 130px;
    overflow: hidden;
  }
  .main {
    margin: auto;
    width: 400px;
    // padding-right: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #79788A;
    max-height: 485px;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 7px 7px 10px 3px #24004628;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 24px;
  }

  /*checkbox to switch from sign up to login*/
  #chk {
    display: none;
  }

  /*Login*/
  .login {
    position: relative;
    width: 100%;
    height: 480px;
  }

  .login label {
    margin: 25% 0 5%;
  }

  label {
    color: #fff;
    font-size: 2rem;
    justify-content: center;
    display: flex;
    font-weight: bold;
    cursor: pointer;
    transition: .5s ease-in-out;
  }

  .input {
    width: 96%;
    height: 25px;
    background: #e0dede;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 4px;
  }

  /*Register*/
  .register {
    background: #eee;
    border-radius: 80% / 10%;
    // transform: translateY(1%);
    transition: .8s ease-in-out;
  }

  .register label {
    color: #FE8280;
    transform: scale(.6);
  }

  #chk:checked ~ .register {
    transform: translateY(-60%);
  }

  #chk:checked ~ .register label {
    transform: scale(1);
    margin: 10% 0 5%;
  }

  #chk:checked ~ .login label {
    transform: scale(.6);
    margin: 5% 0 5%;
  }
  /*Button*/
  .form button {
    width: 85%;
    height: 40px;
    margin: 12px auto 10%;
    color: #fff;
    background: #FE8280;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: .2s ease-in;
  }

  .form button:hover {
    background-color: #FAA38C;
  }


.box-card {

  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .clearfix {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }
  .btn .el-form-item__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
